import { stringify } from 'qs';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { yupNumber, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const customersSearchParamsSchema = yupObject({
  query: yupString().nullable().default(null),
  current_customer_id: yupNumber().nullable().default(null),
  current_customer_guid: yupString().nullable().default(null),
});

type CustomersSearchParams = InferType<typeof customersSearchParamsSchema>;

export function useCustomersNavigation() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const customersSearchParams = useMemo(() => {
    return customersSearchParamsSchema.validateSync(
      Object.fromEntries(searchParams),
    );
  }, [searchParams]);

  function navigateToCustomers(
    newSearchParams?: Partial<CustomersSearchParams>,
    options?: { replace: boolean },
  ) {
    navigate(
      {
        pathname: '/customers',
        search: newSearchParams
          ? stringify(newSearchParams, {
              skipNulls: true,
            })
          : undefined,
      },
      options,
    );
  }

  function navigateToCustomersCreate() {
    navigate('/customers/create');
  }

  function navigateToCustomersImport() {
    navigate('/customers/import');
  }

  return {
    customersSearchParams,
    navigateToCustomers,
    navigateToCustomersCreate,
    navigateToCustomersImport,
  };
}
