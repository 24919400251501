import { SidebarContainer } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { CustomersSelection } from './core/CustomersSelection';
import { CustomerCreatePage } from './create/CustomerCreatePage';
import { CustomersImportPage } from './CustomersImportPage';
import { useCustomersNavigation } from './CustomersRouteHelpers';
import { useCustomersList } from './data/CustomerAPI';
import { CustomerDetailsPage } from './details/CustomerDetailsPage';
import { CustomersEmptyList } from './details/CustomersEmptyList';
import { CustomersList } from './list/CustomersList';

export function CustomersPage() {
  const {
    customersSearchParams: { query },
  } = useCustomersNavigation();
  const debouncedQuery = useDebouncedValue(query, 500);
  const {
    list = [],
    data,
    hasNextPage,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useCustomersList(debouncedQuery);
  const [selectedCustomers, setSelectedCustomers] = useState<Set<number>>(
    new Set(),
  );

  useDocumentTitle('Customers');
  useTrackEvent('[STMS] Viewed Customers Page');

  function getTotalCustomersCount() {
    const lastPage = data?.pages[data.pages.length - 1];
    return lastPage?.pagination.total_objects ?? 0;
  }

  return (
    <SidebarContainer
      sidebar={
        <CustomersList
          total={getTotalCustomersCount()}
          customers={list}
          hasNextPage={!!hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          selectedCustomers={selectedCustomers}
          onSelectionChange={setSelectedCustomers}
        />
      }
    >
      <Routes>
        <Route path="create" element={<CustomerCreatePage />} />
        <Route path="import" element={<CustomersImportPage />} />
        <Route
          path="*"
          element={
            selectedCustomers.size > 1 ? (
              <CustomersSelection
                count={selectedCustomers.size}
                onClear={() => {
                  setSelectedCustomers(new Set());
                }}
              />
            ) : !query && !isLoading && list.length === 0 ? (
              <CustomersEmptyList />
            ) : (
              <CustomerDetailsPage customers={list} />
            )
          }
        />
      </Routes>
    </SidebarContainer>
  );
}
